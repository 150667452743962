import classNames from 'classnames';
import Image from 'next/image';
import { ChevronRightIcon, DocumentTextIcon } from '@heroicons/react/24/outline';

import { NavigationItem } from './PublicNavigation';

import { Heading } from '@/components//Fields/Heading';
import { MaxWidthContainer } from '@/components//Layouts/MaxWidthContainer';
import { Translation } from '@/components/Translation';
// import { ButtonAnchor } from '@/components/Fields/Buttons';

type ExpandedNavigationProps = {
  className?: string;
  item: NavigationItem;
  expanded: boolean;
};

export const ExpandedNavigation = ({ className, item, expanded }: ExpandedNavigationProps) => {
  if (!item.children) {
    return;
  }

  const featured = item.children.filter((c) => c.featured);
  const nonFeatured = item.children.filter((c) => !c.featured);

  return (
    <div
      className={classNames(
        'left-0 top-0 -z-10 transform-gpu bg-transparent',
        'lg:fixed lg:pb-20 lg:pt-32',
        'lt-lg:py-10',
        {
          'lg:bg-background-100 lg:dark:bg-background-800': expanded,
          'pointer-events-none -z-50 touch-none transition-all': !expanded,
        },
        className,
      )}
    >
      <MaxWidthContainer className="flex gap-10 lg:items-center lg:p-10 lt-lg:flex-col lt-lg:!p-0">
        <div
          className={classNames('flex lg:w-2/3', {
            'lt-lg:flex-col': featured.length >= 3,
          })}
        >
          {featured.map((child, index) => (
            <a
              key={index}
              className={classNames(
                'flex flex-1 flex-col gap-4 rounded-lg border-2 border-transparent p-2 hover:border-background-600',
                'scale-0 transform-gpu',
                'group',
                {
                  'scale-100 transition-all': expanded,
                  'lt-lg:w-full lt-lg:flex-row': featured.length >= 3,
                },
              )}
              href={child.page_url}
            >
              <div
                className={classNames(
                  'relative flex h-32 w-full items-center justify-center overflow-clip rounded-lg bg-background-200 dark:bg-background-600',
                  {
                    'lt-lg:w-1/3': featured.length >= 3,
                  },
                )}
              >
                {!child.bg && child.icon && <child.icon className="h-20 opacity-70" />}
                {child.bg && (
                  <Image
                    className="absolute inset-0 h-full w-full object-cover opacity-90 group-hover:opacity-100"
                    src={child.bg}
                    alt=""
                    width="270"
                    height="128"
                    loading="lazy"
                  />
                )}
              </div>
              <div
                className={classNames('flex flex-col gap-2 sm:gap-4', {
                  'lt-lg:w-2/3': featured.length >= 3,
                })}
              >
                <Heading level={3}>
                  {child.bg && child.icon && <child.icon className="h-8" />}
                  {child.page_title}
                </Heading>
                <span
                  className={classNames('text-balance scale-0 font-extralight lt-lg:text-xs', {
                    'scale-100': expanded,
                  })}
                >
                  {!child.translate ? (
                    child.description
                  ) : (
                    <Translation name={`nav.expanded.${child.page_title.replaceAll(' ', '-').toLowerCase()}`}>
                      {child.description}
                    </Translation>
                  )}
                </span>
              </div>
            </a>
          ))}
        </div>
        <div className="flex flex-col empty:hidden lg:w-2/3">
          {nonFeatured.map((child, index) => (
            <a
              key={index}
              className={classNames('text-balance scale-0 transform-gpu transition-all', {
                'scale-100': expanded,
                'delay-100': expanded && index === 1,
                'delay-150': expanded && index === 2,
                'delay-200': expanded && index === 3,
                'delay-300': expanded && index === 4,
                'delay-500': expanded && index === 5,
                'delay-700': expanded && index === 6,
                'delay-1000': expanded && index >= 7,
              })}
              href={child.page_url}
            >
              <span className="flex gap-2 rounded-lg border-2 border-transparent p-2 font-semibold hover:border-background-600">
                <ChevronRightIcon className="w-4 shrink-0 lt-sm:hidden" />
                <DocumentTextIcon className="w-4 shrink-0 sm:hidden" />
                {child.page_title}
              </span>
            </a>
          ))}
          {/* {item.readMore && (
            <ButtonAnchor
              className={classNames('ml-8 mr-auto mt-10 scale-0 px-20 lt-sm:w-[calc(100%-4rem)]', {
                'scale-100': expanded,
              })}
              href={item.page_url}
            >
              Read More
            </ButtonAnchor>
          )} */}
        </div>
      </MaxWidthContainer>
    </div>
  );
};
